import moment from 'moment';

const FORM_YEAR = 2023;
const FORM_QUARTER = 1;
const FORM_OFFSET = -50;

const FORM_ACK_OFFSET = -95;

const KPI_YEAR = 2024;
const KPI_QUARTER = 1;
const KPI_OFFSET = -65;

function timelines(year, quarter, offset) {
  const _timelines = [];
  const date = moment().add(offset, "days");

  while (date.year() > year || (date.year() === year && date.quarter() >= quarter)) {
    _timelines.push(`Q${date.quarter()}/${date.year()}`);
    date.add(-1, "quarter");
  }
  return _timelines;
}

function dueDates(year, quarter, offset) {
  const _dueDates = {};
  const date = moment().add(offset, "days");

  while (date.year() > year || (date.year() === year && date.quarter() >= quarter)) {
    const _week = (date.quarter() * 13) - 2;
    const _dueDate = moment().year(date.year()).isoWeek(_week).isoWeekday(5).format("D MMM YYYY");
    _dueDates[`Q${date.quarter()}/${date.year()}`] = _dueDate;
    date.add(-1, "quarter");
  }
  return _dueDates;
}

export const FORM_TIMELINES = timelines(FORM_YEAR, FORM_QUARTER, FORM_OFFSET);

export const FORM_DUE_DATES = dueDates(FORM_YEAR, FORM_QUARTER, FORM_OFFSET);

export const ACK_TIMELINES = timelines(FORM_YEAR, FORM_QUARTER, FORM_ACK_OFFSET);

export const KPI_TIMELINES = timelines(KPI_YEAR, KPI_QUARTER, KPI_OFFSET);
